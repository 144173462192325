import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

function Process() {
  const data = useLocation();

  const navigete = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const amount = urlParams.get("amount");
  const userId = urlParams.get("userID");

 
//   useEffect(() => {
//     setTimeout(() => {
//       navigete("/success?amount=" + amount + "&transaction_id=" + "fsdsdf");
//     }, 5000);
//   }, []);

  return (
    <div className=" wh-100 container w-100">
      <div className="row  wh-100 justify-content-center align-items-center">
        <h6>process...</h6>
      </div>
    </div>
  );
}

export default Process;
