import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { allapiAction } from "../Redux/common/action";
import RightContainer from "./RightContainer";

function Login2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useParams();
  const [box, setBox] = useState("0");
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");

  

  const hendeleToLogin = () => {
    dispatch(allapiAction.login({ number }));
    setBox("1");
    return () => {};
  };
  const hendelotpVerfy = () => {
    dispatch(
      allapiAction.otpVerify({ number, otp, referBy: data?.id }, navigate)
    );
    setBox("1");
    return () => {};
  };

  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      setTimeout(() => {
        // deshboard_state?.proCount
        navigate("/");
      }, 1000);
    }
  }, []);

  return (
    <div>
      <div id="root">
        {/* <!------Nav Menu Start------> */}

        {/* <div id="menufade" onclick="closeMenu()" className=""></div> */}

        {/* <!------Nav Menu End------>

		<!------Hedar Start------> */}

        <div className="leftContainer">
          <div className="main-area">
            <div style={{ overflowY: "hidden" }}>
              <div className="splash-overlay"></div>
              <div className="splash-screen">
                <figure>
                  <img
                    width="100%"
                    src="../assest/image/global-gameSheetSplash.png"
                    alt=""
                  />
                </figure>
              </div>
              <form
                name="loginform"
                // action="#"
                // method="post"
              >
                <input
                  type="hidden"
                  name="_token"
                  value="RVbsNflMp4KHUHEbwc5a8DkYEykTNXwITSYl5gtL"
                />{" "}
                <div
                  className="position-absolute w-100 center-xy mx-auto"
                  style={{ top: " 45%", zIndex: "4" }}
                >
                  <div className="d-flex text-white font-15 mb-4">
                    Sign in or Sign up
                  </div>
                  <div
                    className="bg-white px-4 cxy flex-column height-70"
                    id="incheight"
                    style={{ width: "85%", borderRadius: "5px" }}
                  >
                    <div
                      className="input-group"
                      style={{ transition: "top 0.5s ease 0s" }}
                    >
                      <div className="input-group-prepend">
                        <div
                          className="input-group-text"
                          style={{ width: "100px" }}
                        >
                          +91
                        </div>
                      </div>
                      <input
                        className="form-control"
                        name="mobile"
                        id="mobile"
                        type="number"
                        placeholder="Mobile number"
                        min="0000000000"
                        maxLength="10"
                        style={{ transition: "all 3s ease-out 0s" }}
                        required=""
                        fdprocessedid="gsqi29"
                        disabled={box == "1" ? true : false}
                        onChange={(e) => {
                          setNumber(e.target.value);
                        }}
                      />
                    </div>
                    {box == "1" && (
                      <div
                        className="input-group"
                        style={{ transition: "top 0.5s ease 0s" }}
                      >
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            style={{ width: "100px" }}
                          >
                            OTP
                          </div>
                        </div>
                        <input
                          className="form-control"
                          name="mobile"
                          id="mobile"
                          type="number"
                          placeholder="Enter OTP"
                          min="0000000000"
                          maxLength="4"
                          style={{ transition: "all 3s ease-out 0s" }}
                          required=""
                          fdprocessedid="gsqi29"
                          onChange={(e) => {
                            setOtp(e.target.value);
                          }}
                        />
                      </div>
                    )}

                    {/* <div
                        className="input-group pt-2 otp"
                        style={{
                          transition: "left 0.5s ease 0s",
                          // display: "none",
                        }}
                      >
                        <div className="input-group-prepend">
                          <div
                            className="input-group-text"
                            style={{ width: "100px;" }}
                          >
                            OTP
                          </div>
                        </div>
                        <input
                          className="form-control"
                          name="otp"
                          type="number"
                          placeholder="Enter OTP"
                          autocomplete="off"
                          value=""
                          required=""
                        />
                        <div className="invalid-feedback">
                          Enter a correct OTP
                        </div>
                      </div> */}
                    {/* <span id="error" className="error"></span> */}
                  </div>

                  <button
                    type="submit"
                    className="bg-green refer-button cxy mt-4 otp"
                    style={{ width: "85%", display: "none" }}
                  >
                    lOGIN
                  </button>
                  {box == "0" ? (
                    <a
                      href="#"
                      className="bg-green refer-button cxy mt-4 send-otp"
                      onClick={() => {
                        hendeleToLogin();
                      }}
                      style={{ width: "85%" }}
                    >
                      Send OTP
                    </a>
                  ) : (
                    <a
                      href="#"
                      className="bg-green refer-button cxy mt-4 send-otp"
                      onClick={() => {
                        hendelotpVerfy();
                      }}
                      style={{ width: "85%" }}
                    >
                      Verify
                    </a>
                  )}

                  {/* <div> */}
                  {box == "1" && (
                    <a
                      href="#"
                      className="bg-green refer-button cxy mt-4 send-otp"
                      onClick={() => {
                        setBox("0");
                      }}
                      style={{ width: "85%" }}
                    >
                      Change number
                    </a>
                  )}
                  {/* </div> */}
                </div>
              </form>

              <div className="login-footer">
                By proceeding, you agree to our{" "}
                <a href="/term-condition">Terms of Use</a>,{" "}
                <a href="/privacy-policy">Privacy Policy</a> and that you are 18
                years or older. You are not playing from Assam, Odisha,
                Nagaland, Sikkim, Meghalaya, Andhra Pradesh, or Telangana.
              </div>
            </div>
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Login2;
