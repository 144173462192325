import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import RightContainer from "./RightContainer";
import moment from "moment";
function Notification() {
  const dispatch = useDispatch();

  const get_notification = useSelector((state) =>
    state?.allapi?.get_notification ? state?.allapi?.get_notification : []
  );

  console.log(get_notification);

  useEffect(() => {
    // notificationseen
    dispatch(allapiAction.notificationseen());
    dispatch(allapiAction.getusernotification());
    return () => {};
  }, []);

  // getusernotification
  return (
    <div>
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />

          <div className="main-area" style={{ paddingTop: "60px" }}>
            {get_notification &&
              get_notification?.map((data, i) => {
                return (
                  <div className="d-flex px-4 py-3 list-item">
                    {/* <picture className="mr-3">
                <img
                  height="36px"
                  width="36px"
                  src="../assest/image/kyc-icon-new.png"
                  alt=""
                />
              </picture> */}
                    <div>
                      <span className="font-9 fw-800 d-flex">{data?.type}</span>
                      <small className="d-flex font-7 my-2">
                        {data?.description}
                      </small>
                      <small
                        className="d-flex font-7"
                        style={{ color: "rgb(199, 199, 199)" }}
                      >
                        {/* 7 Oct 11:10 AM */}
                        {moment(data?.createdAt)?.format("DD MMM YYYY hh:MM a")}
                      </small>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default Notification;
