import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Faild() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 2000);
  }, []);

  return (
    <div className="w-full ">
      <div className="container">
        <div className="row   successorder">
          {/* <div className="col-12"> */}
          <img
            // style={{ width: "250px" }}
            src="../assest/image/failed.png"
            alt=""
          />
          <h1 className="text-2xl font-semibold">Transaction Failed</h1>
        </div>
      </div>
    </div>
  );
}

export default Faild;
