import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../Layout/Header";
import SiderBar from "../Layout/SiderBar";
import { allapiAction } from "../Redux/common/action";
import RightContainer from "./RightContainer";
import moment from "moment";

function HameHistory() {
  const dispatch = useDispatch();
  const battel_List_users = useSelector((state) =>
    state?.allapi?.battel_List_users ? state?.allapi?.battel_List_users : {}
  );

  const profile = useSelector((state) =>
    state?.allapi?.profile ? state?.allapi?.profile : {}
  );

 

  useEffect(() => {
    // battel_List
    dispatch(allapiAction.getbattellistUser());
    return () => {};
  }, []);

  return (
    <div>
      <div id="root">
        <SiderBar />

        <div className="leftContainer">
          <Header />
          <div className="main-area" style={{ paddingTop: "60px" }}>
            {battel_List_users?.complete?.map((data, i) => {
           

              const userbet =
                profile?.users?._id == data?.owner?._id ? "1" : "2";
              const userwin =
                userbet == "1" ? data?.ownerresult : data?.playeroneresult;
              const userwinagin =
                userbet == "1" ? data?.playerone?.uid : data?.owner?.uid;
            console.log(data)

              return (
                <div className="main-area">
                  <div className="w-100 py-3 d-flex align-items-center list-item">
                    <div className="center-xy list-date mx-2">
                      <div>
                        {moment(data?.createdAt)?.format("DD MMM")}{" "}
                        {/* 03 Feb */}
                      </div>
                      <small>
                        {" "}
                        {moment(data?.createdAt)?.format("hh:mm A")}{" "}
                        {/* 01:10 PM */}
                      </small>
                    </div>
                    <div className="list-divider-y"></div>
                    <div className="mx-3 d-flex list-body">
                      <div className="d-flex align-items-center">
                        <picture className="mr-2">
                          <img
                            height="32px"
                            width="32px"
                            src="../assest/image/games/kb_ludo_classic.jpeg"
                            alt=""
                            style={{ borderRadius: "5px" }}
                          />
                        </picture>
                      </div>
                      <div className="d-flex flex-column font-8">
                        <div>
                          {userwin} against <b>{userwinagin}</b>.
                        </div>
                        <div className="games-section-headline">
                          Battle ID: {data?._id}
                        </div>
                      </div>
                    </div>
                    <div className="right-0 d-flex align-items-end pr-3 me-3 flex-column">
                      <div className="d-flex float-right font-8">
                        <div
                          className={
                            userwin == "win" ? "text-success" : "text-danger"
                          }
                        >
                          ( {userwin == "win" ? "+" : "-"} )
                        </div>
                        <picture className="ml-1 mb-1">
                          <img
                            height="21px"
                            width="21px"
                            src="../assest/image/global-rupeeIcon.png"
                            alt=""
                          />
                        </picture>
                        <span className="pl-1"> {data?.amount} </span>
                      </div>
                      <div
                        className="games-section-headline"
                        style={{ fontSize: "0.6em" }}
                      >
                        Prize:{data?.prize}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="divider-y"></div>
        <RightContainer />
      </div>
    </div>
  );
}

export default HameHistory;
